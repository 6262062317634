<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='订单号'>
            <a-input placeholder="Id" v-model="searchForm.Id"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='状态'>
            <a-input placeholder="Status" v-model="searchForm.Status"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='修理厂'>
              <a-input placeholder="Status" v-model="searchForm.GarageName"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='配件商'>
            <a-select
                show-search
                v-model="searchForm.PartitionId"
                placeholder="Select a person"
                option-filter-prop="children"
                style="width: 100%"
                :filter-option="filterASUserListOption"
              >
                <a-select-option v-for="it in asUserList" :key="it.partitionId">{{
                  it.name
                }}</a-select-option>
              </a-select>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='订单时间'>
            <a-range-picker v-model="searchForm.CreatedAt" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    
    
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col style="display: flex;">
          <!-- <a-popover v-model="exportTypeSelectShow" title="请选择导出类型"  trigger="click" v-if="$root.$data.privateState.action.includes('/AccessoriesOrders/SettlementExcel') || $root.$data.privateState.action.includes('/AccessoriesOrders/ExportStatistical') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">
            <a-row slot="content" :gutter="[8, 24]" justify="center">
              <a-col style="text-align: center;" v-if="$root.$data.privateState.action.includes('/AccessoriesOrders/SettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">
                <a-tag color="orange" @click="exportSettlementExcelClick('await')">导出待结算列表</a-tag>
              </a-col>
              <a-col style="text-align: center;" v-if="$root.$data.privateState.action.includes('/AccessoriesOrders/SettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">
                <a-tag color="green" @click="exportSettlementExcelClick('done')">导出已结算列表</a-tag>
              </a-col>
            </a-row>
            <a-button icon="cloud-download" style="background-color: #909399;color: #fff;border-color: #909399;" >导出结算表或统计表</a-button>
          </a-popover>
          <a-divider type="vertical" style="height:100%" v-if="$root.$data.privateState.action.includes('/AccessoriesOrders/PostBackSettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)" />
          <div v-if="$root.$data.privateState.action.includes('/AccessoriesOrders/PostBackSettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">
              <a-upload
                name="file"
                :file-list="uploadSettlementExcel"
                :action="uploadSettlementUrl"
                :headers="uploadSettlementHeaders"
                :multiple="false"
                @change="handleChangeUploadSettlementExcel"
            >
              <a-button icon="cloud-upload" type="primary">回传结算表</a-button>
            </a-upload>
          </div> -->
          <a-divider type="vertical" style="height:100%" />
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="record => record.id"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 1300 }"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #status="status, data">
          <a-tag :color="customerStatusDic[status].color">{{customerStatusDic[status].label}}</a-tag>
          <a-tooltip v-if="data.backMessage">
            <template slot="title">
              {{data.backMessage}}
            </template>
            <a-tag color="red" style="borderStyle: dashed;">退回</a-tag>
          </a-tooltip>
        </template>
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template #action="data">
          <a-button v-if="data.status == 200 && ($root.$data.privateState.role === 'SuperAdmin' || $root.$data.privateState.action.includes('/AccessoriesOrders/ConfirmVerify'))" type="link" @click="confirmVerifyClick(data)">财务审核</a-button>
        </template>
      </a-table>
    </div>
    <!-- table end -->
    <a-modal
      title="订单审核"
      v-model="confirmVerifyShow"
      @ok="confirmVerifyOk"
      :keyboard="false"
      :maskClosable="false"
      width="60%"
    >
      <a-form-model ref="confirmVerifyForm" :model="confirmVerifyForm" :rules="confirmVerifyFormRules" v-viewer="{movable: false}" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="审核状态">
          <a-select
                v-model="confirmVerifyStatus"
                style="width: 100%"
              >
                <a-select-option v-for="it in [{key: 'pass', name: '通过'},{key: 'back', name: '退回'}]" :key="it.key">{{
                  it.name
                }}</a-select-option>
              </a-select>
        </a-form-model-item>
        <div v-if="confirmVerifyStatus == 'back'">
          <a-form-model-item label="退回说明">
            <a-textarea
              v-model="backMessage"
              placeholder="请填写退回说明"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-model-item>
        </div>
        <div v-else>
          <a-form-model-item label="付款金额" prop="amount">
            <a-input-number style="width:100%" :min="0" v-model="confirmVerifyForm.amount" />
          </a-form-model-item>
          <a-form-model-item label="费用付款账户" prop="bankCard">
            <a-select
              notFoundContent="请先选择"
              :allowClear="true"
              v-model="confirmVerifyForm.bankCard"
            >
              <a-select-option
                v-for="it in paymentBankList"
                :key="it.bankCard"
                >
                <a-tag v-if="it.type === 'company'" color="blue">公户</a-tag>
                <a-tag v-else-if="it.type === 'private'" color="purple">私户</a-tag>
                {{ it.bankAccount }}({{it.bankCard}})
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>
    <!-- 结算表 -->
    <a-modal
      v-model="showExportSettlementExcelDateSelect"
      title="导出结算或统计表表(时间范围为订单建立时间)"
      @ok="exportSettlementExcel"
      @cancel="showExportSettlementExcelDateSelect = false"
    >
      <a-row>
        <a-range-picker style="width:100%" :disabled-date="c => c > moment().endOf('day')" v-model="exportSettlementExcelDate" />
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import screenfull from 'screenfull'
import DownloadJS from "downloadjs";

import { AccessoriesOrders, ASUsers, AppPaymentAccount } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'

const INIT_SEARCH_FORM = '{"Id":"","GarageName":"","Status":"","PartitionId":"","CreatedAt":[]}'
const columns = [
  {
    title: 'ID',
    width: 150,
    dataIndex: 'id',
  },
  {
    title: '状态',
    width: 150,
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '商品总额',
    width: 150,
    dataIndex: 'amount',
  },
  {
    title: '使用配件额度',
    width: 150,
    dataIndex: 'usedScore',
  },
  {
    title: '修理厂',
    width: 150,
    dataIndex: 'garageName',
  },
  {
    title: '供应商',
    width: 120,
    dataIndex: 'partitionId',
  },
  {
    title: '订单时间',
    dataIndex: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 130,
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  }
]
export default {
  name: 'AccessoriesOrdersTableList',
  mixins: [aliveVM, mixinTableList],
  created () {
    const baseColumns = []
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    this.$set(this, 'baseColumns', baseColumns)
    this.fetch()
    this.fileUploadHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    this.uploadSettlementUrl = `${this.$appBaseUrl}${AccessoriesOrders}/PostBackSettlementExcel`
    this.uploadSettlementHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  },
  async mounted () {
      await this.getASUserList();
      await this.getPaymentBankList();
  },
  computed: {
    showColumns() {
      let c = []
      for (const it of this.columns) {
        c.push(it.title)
      }
      return c
    }
  },
  data () {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      screenfull,
      isFullscreen: false,
      expand: false,
      tableListApi: `${AccessoriesOrders}/TableList`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      // 其他
      moment,
      customerStatusDic: {
        '100': {
          label: '待修理厂确认',
          color: '#FF5722',
        },
        '200': {
          label: '待财务审核',
          color: '#FF9800',
        },
        '300': {
          label: '待付款',
          color: '#9E9E9E',
        },
        '999': {
          label: '已完成',
          color: '#4CAF50',
        },
      },
      organizationTree: [],
      customerFormRules: {
      },
      asUserList: [],
      // 订单付款
      orderId: '',
      paymentBankList: [],
      confirmVerifyShow: false,
      confirmVerifyStatus: 'pass',
      backMessage: '',
      confirmVerifyForm: {
        amount: 0,
        bankCard: '',
      },
      confirmVerifyFormRules: {
        amount: [{ required: true, message: "请输入", trigger: "blur" }],
        bankCard: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
      // 结算表
      uploadSettlementUrl: '',
      uploadSettlementHeaders: {},
      exportTypeSelectShow: false,
      exportType: '',
      uploadSettlementExcel: [],
      showExportSettlementExcelDateSelect: false,
      exportSettlementExcelDate: [],
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', null)
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', null)
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === 'Role' && it) {
          searchParams[k] = parseInt(it)
          continue
        }
        if (k === 'Belong' && it) {
          searchParams[k] = it.key
          continue
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // other
    async getPaymentBankList() {
      const apiRes = await this.$axios
        .get(`${AppPaymentAccount}/SelectList`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return null;
      }
      this.paymentBankList = apiRes.data
    },
    async getASUserList() {
      const res = await this.$axios
        .get(`${ASUsers}/GetSelectList`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.$message.error(res.message);
        return;
      }
      this.$set(this, "asUserList", res.data);
    },
    filterASUserListOption(input, option) {
        return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    confirmVerifyClick(data) {
      this.orderId = data.id;
      let bankCard = '';
      for (const it of this.paymentBankList) {
        if (it.bankCard === '53050161536200000545') {
          bankCard = '53050161536200000545';
          break;
        }
      }
      this.confirmVerifyStatus = 'pass'
      this.backMessage = ''
      this.$set(this, 'confirmVerifyForm', {
        amount: data.usedScore,
        bankCard: bankCard,
      })
      this.confirmVerifyShow = true
    },
    async confirmVerifyOk() {
      if (this.confirmVerifyStatus === 'back') {
        if (!this.backMessage) {
          this.$message.error('退回请填写说明');
          return;
        }
        await this.BackToConfirmOrder();
        return;
      }
      this.$refs.confirmVerifyForm.validate(async (valid) => {
        if (!valid) {
          this.$message.error("请检查填写内容");
          return;
        }
        const postData = JSON.parse(JSON.stringify(this.confirmVerifyForm));
        const res = await this.$axios
          .post(`${AccessoriesOrders}/ConfirmVerify?id=${this.orderId}`, postData)
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });

        if (!res.status) {
          this.$message.error(res.message);
          return;
        }
        this.confirmVerifyShow = false;
        this.$message.success("操作成功");
        this.fetch();
      })
    },
    async BackToConfirmOrder()
    {
      const res = await this.$axios
          .post(`${AccessoriesOrders}/BackToConfirmOrder`, {
            id: this.orderId,
            message: this.backMessage
          })
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });

        if (!res.status) {
          this.$message.error(res.message);
          return;
        }
        this.confirmVerifyShow = false;
        this.$message.success("操作成功");
        this.fetch();
    },
    async exportSettlementExcelClick(type)
    {
      this.exportTypeSelectShow = false
      this.exportType = type
      this.exportSettlementExcelDate = []
      this.showExportSettlementExcelDateSelect = true
    },
    async exportSettlementExcel() {
      if (!this.exportSettlementExcelDate || this.exportSettlementExcelDate.length < 2) {
        this.$message.error('请选择导出月份');
        return;
      }
      const searchParams = {
        createdAt: [this.exportSettlementExcelDate[0].format('YYYY-MM-DD') + ' 00:00:00', this.exportSettlementExcelDate[1].format('YYYY-MM-DD')  + ' 23:59:59']
      }
      
      this.sending = true
      this.sendingHint = '正在获取数据'
      if (this.exportType == 'statistics') {
        const apiRes = await this.$axios.post(`${AccessoriesOrders}/ExportStatistical`, {
          results: 20000,
          page: 1,
          searchParams,
        }).catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
        this.sending = false
        if (!apiRes.status) {
          this.sending = false
          this.$message.error(apiRes.message)
          return
        }
        this.showExportSettlementExcelDateSelect = false
        DownloadJS(window.atob(apiRes.data), '统计表数据.xlsx')
        return
      }
      const apiRes = await this.$axios.post(`${AccessoriesOrders}/SettlementExcel?type=${this.exportType}`, {
        results: 20000,
        page: 1,
        searchParams,
      }).catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
      this.sending = false
      if (!apiRes.status) {
        this.sending = false
        this.$message.error(apiRes.message)
        return
      }
      this.showExportSettlementExcelDateSelect = false
      DownloadJS(window.atob(apiRes.data), '结算表数据.xlsx')
    },
    handleChangeUploadSettlementExcel(info) {
      this.uploadSettlementExcel = [info.fileList[info.fileList.length - 1]]
      if (info.file.status === 'error') {
        this.$message.error('上传文件失败，请尝试重新登录');
        return
      } else if (info.file.status === 'done') {
        if (!info.file.response.status) {
          this.$message.error(info.file.response.message);
          return;
        }
        this.uploadSettlementExcel = []
        this.$message.success('回传结算表成功！')
        this.fetch();
      }
    },
  }
}
</script>

<style>

</style>